import axios from '../plugins/login';

import qs from 'qs';

// form 表单传参
const form = {
  transformRequest: [
    function (data) {
      data = qs.stringify(data);
      return data;
    }
  ],
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

// 查询是否登录
const isLogin = () => axios.get('/auth/isLogin');

// 获取token
const token = async data => axios.post('/sub/auth/login/subApp', data, form);

// 获取用户信息
const userInfo = async () => axios.get('/user/get');

// 设置初始密码
const initPassWord = async data =>
  axios.post('/user/password/set/init', data, form);

// 修改密码
const editPassWord = async data =>
  axios.post('/user/password/modify', data, form);

// 修改密码
const logout = async () => axios.post('/auth/logout');

// 修改用户信息接口，只需要传需要修改的字段
const userInfoModify = async data =>
  axios.post('/user/info/modify', data, form);

// 修改手机号-发送验证码接口
const userPhoneCode = async data =>
  axios.post('/user/phone/change/code/send', data, form);

// 修改手机号接口
const changeUserPhone = async data =>
  axios.post('/user/phone/change', data, form);

export {
  isLogin,
  token,
  userInfo,
  initPassWord,
  editPassWord,
  logout,
  userInfoModify,
  userPhoneCode,
  changeUserPhone
};
