import Vue from 'vue';
import {
  Message,
  Container,
  Aside,
  Main,
  Header,
  Carousel,
  CarouselItem,
  Input,
  Rate,
  Dialog,
  Button,
  RadioGroup,
  RadioButton,
  Radio,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Upload,
  Select,
  Option,
  Pagination,
  Cascader,
  Loading,
  InfiniteScroll,
  Tooltip,
  Popconfirm,
  Image,
  Table,
  TableColumn,
  DatePicker
} from 'element-ui';

Vue.prototype.$message = Message;
Vue.use(Container)
  .use(Aside)
  .use(Main)
  .use(Header)
  .use(Carousel)
  .use(Input)
  .use(Rate)
  .use(Dialog)
  .use(Button)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Checkbox)
  .use(Radio)
  .use(CheckboxGroup)
  .use(CheckboxButton)
  .use(Upload)
  .use(Pagination)
  .use(Select)
  .use(Option)
  .use(CarouselItem)
  .use(Loading)
  .use(Loading.directive)
  .use(InfiniteScroll)
  .use(Tooltip)
  .use(Popconfirm)
  .use(Cascader)
  .use(Table)
  .use(TableColumn)
  .use(DatePicker)
  .use(Image);
