import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginState: false,
    city: {},
    ticket: '',
    locationCity: {},
    modelData: {}
  },
  mutations: {
    setLoginState(state, val) {
      state.loginState = val;
    },
    setCity(state, val) {
      state.city = val;
    },
    setLocationCity(state, val) {
      state.locationCity = val;
    },
    setModelData(state, val) {
      state.modelData = val;
    },
    setTicket(state, val) {
      state.ticket = val;
    }
  },
  actions: {},
  modules: {}
});
