import axios from '../plugins/common';

// import qs from 'qs';

// // form 表单传参
// const form = {
//   transformRequest: [
//     function (data) {
//       data = qs.stringify(data);
//       return data;
//     }
//   ],
//   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
// };
// 按树形结构查询所有地区
const areaAllTree = () => axios.get('/area/all/tree');

// 查询所有地区
const areaAll = () => axios.get('/area/all');

// 根据上级查询所有
const getAreaByParent = params => axios.get('/area/getByParent', { params });

// 根据ip定位开放城市
const ipLocationCity = params =>
  axios.get('/ip/location/city/open', { params });

// 地区-按树形查询所有开放城市
const openCityTree = params => axios.get('/area/openCity/all/tree', { params });

// 地区-条件查询所有开放城市
const openCityAll = params => axios.get('/area/openCity/all', { params });

// 根据名称搜索城市
const searchByName = params =>
  axios.get('/area/openCity/searchByName', { params });

// 配套设施-根据类型查询
const facilitiesList = params =>
  axios.get('/build/facilities/list', { params });

// 房源特色-根据类型查询标签
const labelList = params => axios.get('/build/label/list', { params });

// 获取价格和面积标签
const getPriceAndArea = params =>
  axios.get('/building/condition/label/priceAndArea', { params });

// 查询矩形范围内的地区（城市内）
const searchAreaRect = params => axios.get('/area/search/rect', { params });

// 周边搜索
const mapRangeSearch = params => axios.get('/map/search/range', { params });

// 按行政区搜索
const mapRegionSearch = params => axios.get('/map/search/region', { params });

export {
  areaAllTree,
  areaAll,
  getAreaByParent,
  ipLocationCity,
  openCityTree,
  facilitiesList,
  labelList,
  openCityAll,
  searchByName,
  getPriceAndArea,
  searchAreaRect,
  mapRangeSearch,
  mapRegionSearch
};
