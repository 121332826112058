import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/axios';
import './plugins/element.js';
import 'element-ui/lib/theme-chalk/index.css';
import './plugins/vant.js';
import md5 from 'js-md5';
import './styles/reset.less';
import './styles/element.less';
import 'vant/lib/index.css';
import './styles/icon.less';
import * as filters from './filters';

Vue.config.productionTip = false;
// 这个方式可以在任何组件里直接用 this.$EventBus 调用
var EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus;
    }
  }
});
//将md5转换成vue原型：
Vue.prototype.$md5 = md5;

// 全局过滤
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]); // 插入过滤器名和对应方法
});

// 地图引入
// window.onload = function () {
//   // eslint-disable-next-line
//   window.BMapGL = BMapGL;
// };
// // 如果已加载直接返回
// if (typeof BMapGL !== 'undefined') {
//   // eslint-disable-next-line
//   window.BMapGL = BMapGL;
// }
// // 百度地图异步加载回调处理
// window.onBMapCallback = function () {
//   // eslint-disable-next-line
//   window.BMapGL = BMapGL;
//   console.log('onBMapCallback', window.BMapGL);
// };
// // const ak = 'rAHTYQTI88gCQXsym3xDmdBeg6PShkOw';
// // const ak = '0G6AY6KiQjnfzWGRMPGCEKkVVrosknjp';
// const ak = '26z9D0KsGMqPXjNW8vVV3Kfa4zkS2BDg';
// const script = document.createElement('script');
// script.type = 'text/javascript';
// script.src = `https://api.map.baidu.com/api?v=2.0&type=webgl&ak=${ak}&callback=onBMapCallback&s=1`;
// document.head.appendChild(script);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
