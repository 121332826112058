/**
 * 注释：手机号过滤
 * 131XXXX8158 => 13****58
 * @param {number} value
 * @return {string}
 */
export function phoneFilter(value) {
  if (value) {
    let str = value.toString();
    return str.substring(0, 3) + '****' + str.slice(str.length - 4, str.length);
  } else {
    return '';
  }
}

/**
 * 注释：可租时间过滤
 * 202311 => 2023年11月
 * @param {number} value
 * @return {string}
 */
export function canLeaseTimeFilter(canLeaseTime) {
  var text = '';
  if (canLeaseTime) {
    var canLeaseTimeStr = canLeaseTime.toString();
    var year = canLeaseTimeStr.slice(0, 4);
    var month = canLeaseTimeStr.slice(4, 6);
    var date = Date.parse(year + '-' + month);
    var now = Date.now();
    if (now > date) {
      text = '随时可租';
    } else {
      text = year + '年' + month + '月';
    }
  }
  return text;
}

/**
 * 注释：起租时间过滤
 * @param {number} value
 * @return {string}
 */
export function minLeaseMonthFilter(value) {
  if (value < 12) {
    return `${value}个月`;
  } else {
    return `${value / 12}年`;
  }
}
