<template>
  <div class="login"></div>
</template>

<script>
import { token, isLogin, userInfo } from 'services/login';
export default {
  name: 'Login',
  data () {
    return {
    };
  },
  computed: {
  },
  mounted () {
    this.getToken();
  },
  methods: {
    judgingLogin () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          if (!res.data.data) {
            localStorage.removeItem('userInfo')
          } else {
            // 获取用户信息
            userInfo().then(r => {
              if (r.data.status === 200) {
                localStorage.userInfo = JSON.stringify(r.data.data)
              }
            })
          }
        }
      })
    },
    async getToken () {
      const hash = decodeURIComponent(window.location.hash)
      let ticket = hash.split('ticket=')[1]
      let url = hash.split('from=#')[1].split('&')[0]
      if (ticket !== '404') {
        let res = await token({ client: 'web', ticket });
        // 储存token
        localStorage.token = res.data.data.token;
      }
      this.$store.commit('setTicket', ticket)
      sessionStorage.ticket = ticket

      // 获取用户信息
      // this.judgingLogin()
      // 跳转到首页
      this.$router.push(url);
    }
  }
};
</script>
